import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { AiOutlineFileSync } from "react-icons/ai";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import { getEvents, syncEvents } from "../../services/services";
import { constant } from "../../utils/constants";
import { getTableIndex } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";

const Shifts = () => {
  const [search, setSearch] = useState();
  const [page, setPage] = useState(constant.PAGE_NO_ONE);

  const { data, refetch } = useQuery({
    queryKey: ["shift-list", page],
    queryFn: async () => {
      let params = {
        page: page, limit: constant.PAGE_LIMIT, sortedBy: constant.SORTED_BY.START_TIME, sortBy: constant.SORT_BY.ASC
      };
      if (search) params.search = search;
      const resp = await getEvents(params);
      return resp?.data?.data || {};
    },
  });

  const handleEventSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to your google calendar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncEventsMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncEventsMutation = useMutation({
    mutationFn: () => syncEvents(),
    onSuccess: async (resp) => {
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });
  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Upcoming Schedules</h4>
        <div className="endContent">
          <button
            className="greenBtn"
            type="button"
            title="Sync with google calendar"
            onClick={handleEventSync}
          >
            <AiOutlineFileSync size={30} />
          </button>
          <div className="clientSearch">
            <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            />
            <input
              type="text"
              placeholder="Type and press enter to search."
              className="clientSearchInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key == "Enter" && search.trim() !== "" && refetch()
              }
              onKeyUp={(e) =>
                e.target.value == "" && e.key == "Backspace" && refetch()
              }
            />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th>S. No</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Client Name</th>
                    <th>Note</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.events && data?.events?.length > 0 ? (
                    data?.events?.map((event, index) => {
                      const clientDetails = event?.clientDetails || null;
                      return (
                        <tr key={`event_${index}`}>
                          <td> {getTableIndex(page, index)}</td>
                          <td>{moment(event?.startTime).format("ll")}</td>
                          <td>
                            {moment(event?.startTime).format("LT")} -{" "}
                            {moment(event?.endTime).format("LT")}
                          </td>
                          <td className="text-capitalize">{`${clientDetails?.firstName} ${clientDetails?.lastName}`}</td>
                          <td>{event?.note || "--"}</td>
                          <td>{`${clientDetails?.userAddress?.address} ${clientDetails?.userAddress?.city} ${clientDetails?.userAddress?.state} ${clientDetails?.userAddress?.country} (${clientDetails?.userAddress?.zipCode})`}</td>
                          <td>{clientDetails?.phoneNumber}</td>
                          <td>{clientDetails?.email}</td>
                          <td>{moment(event?.createdAt).format("lll")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={data?.total_pages}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shifts;
