import React from "react";
import { Link, NavLink } from "react-router-dom";
import * as images from "../assets/image";
import useRole from "../hooks/useRole";
import { constant } from "../utils/constants";

const Sidebar = () => {
  const role = useRole();
  return (
    <>
      <div className={`sidebar`}>
        <Link className="logoArea" role="button" to="/">
          <div>
            <img src={images.calender} alt="image" className="sidebarLogo" />
          </div>
          <h5>Scheduler</h5>
        </Link>

        {role == constant.ROLE.ADMIN ? (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/calendar">
                Calendar
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/client">
                Client
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/employee">
                Employee
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/time-off">
                Time Off
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/payout">
                Payroll
              </NavLink>
            </li>
          </ul>
        ) : role == constant.ROLE.EMPLOYEE ? (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/calendar">
                Calendar
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/shifts">
                Schedules
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/add-timesheet">
                Timesheet
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/payout">
                Payout
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/time-off">
                Time Off
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/calendar">
                Appointments
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/request">
                Request
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/timesheet">
                Timesheets    
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Sidebar;
