import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { toastAlert } from '../utils/SweetAlert'


export default function SignatureCanva(props) {
    const { show, handleClose, setSigned } = props
    const signRef = useRef(null)

    const handleClear = () => {
        if (signRef.current) signRef.current.clear()
        else console.log("Ref is not working for signature canvas")
    }

    const saveSignature = () => {
        if (signRef && signRef.current) {
            const isEmpty = signRef.current.isEmpty()
            if (isEmpty) {
                toastAlert("error", "Please sign before saving")
                return null
            }
            const signurl = signRef.current.getTrimmedCanvas().toDataURL('image/png')
            setSigned(signurl)
            handleClose()
        } else toastAlert("error", "Some error occured with signature canvas")
    }

    return (
        <Modal show={!!show} onHide={handleClose} centered className="addModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sign the form
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SignatureCanvas
                    ref={signRef}
                    penColor='green'
                    canvasProps={{ width: 600, height: 200, className: 'sigCanvas' }} />
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex align-items-center gap-3 ">
                    <button
                        className="greenBtn"
                        type="button"
                        onClick={saveSignature}
                    >
                        Save Changes
                    </button>
                    <button onClick={handleClear} type="button" className="cancleBtn">
                        Clear
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
