import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useRef, useState } from "react";
import { AiOutlineCloudSync, AiOutlineFileSync } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { MdAssignmentInd } from "react-icons/md";
import Swal from "sweetalert2";
import FilterModal from "../../common/FilterModal";
import {
  assignEvents,
  deleteEvent,
  getEvents,
  updateEvent,
  updateEventStatus,
  updateEventSteps,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import AddEvent from "./AddEvent";

const Calendar = () => {
  const clickTimer = useRef(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const calendarRef = useRef(null);
  const [currentView, setCurrentView] = useState("");

  const [filterShow, setFilterShow] = useState(false)
  const [filterData, setFilterData] = useState({})
  const handleFilterShow = () => setFilterShow(true)

  const getEventStyle = (eventObj, employeeDuration) => {
    let classname = "";
    let backgroundColor = "red";
    let isOverBooked = false;

    const startDate = new Date(eventObj?.startTime); // Handle timezone shift
    const fullDate = startDate.toISOString().split("T")[0];

    const isCancelled = eventObj.status === constant.EVENT_STATUS.CANCELLED;
    if (
      employeeDuration &&
      employeeDuration[fullDate] &&
      employeeDuration[fullDate][eventObj.employeeId] &&
      employeeDuration[fullDate][eventObj.employeeId] >
      constant.MAX_DURATION_PER_EMPLOYEE
    ) {
      isOverBooked = true;
    }

    if (isCancelled) {
      // Cancelled events
      classname = "cancelled_event";
      backgroundColor = "blue";
    } else if (!eventObj?.employeeId || eventObj?.employeeId == "") {
      // Not Assigned
      classname = "unassigned_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && isOverBooked) {
      // Employee overboooked over 8 hours and the vent is not synced
      classname = "overbooked_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && eventObj?.steps == 0) {
      // Assigned but not synced
      classname = "notsync_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && eventObj?.steps == 1) {
      // Assigned and synced
      classname = "employee_sync";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && eventObj?.steps == 2) {
      // Assigned and synced
      classname = "synced_assigned_event";
      backgroundColor = "green";
    } else if (isOverBooked) {
      classname = "overbooked_event";
      backgroundColor = "red";
    }

    return { backgroundColor: backgroundColor, className: classname };
  };

  const { data, refetch } = useQuery({
    queryKey: ["event-list", filterData],
    queryFn: async () => {
      let params = {
        sortedBy: "startTime",
        sortBy: "ASC"
      }

      if (Object.keys(filterData).length && Object.keys(filterData).filter(item => filterData[item]['value']).length) {
        Object.keys(filterData).forEach(key => params[key] = filterData[key]['value'])
      }

      const resp = await getEvents(params);

      let calendarEvents = [];

      if (
        resp.data &&
        resp?.data?.data?.events &&
        resp?.data?.data?.events?.length
      ) {
        let employeeDuration = {};
        calendarEvents = resp?.data?.data?.events.map((event) => {
          const clientDetails = event?.clientDetails || null;
          const employeeDetails = event?.employeeDetails || null;

          const startDate = new Date(event?.startTime); // 8 hours color shift code
          const fullDate = startDate.toISOString().split("T")[0];
          if (event.employeeId && event.employeeId !== "") {
            if (employeeDuration[fullDate]) {
              const innerObject = { ...employeeDuration[fullDate] };
              if (innerObject[event.employeeId]) {
                innerObject[event.employeeId] += event.duration;
              } else {
                innerObject[event.employeeId] = event.duration;
              }
              employeeDuration[fullDate] = innerObject;
            } else
              employeeDuration[fullDate] = {
                [event.employeeId]: event.duration,
              };
          }

          const styleProperies = getEventStyle(event, employeeDuration);
          return {
            start: new Date(event?.startTime),
            end: new Date(event?.endTime),
            title: `Client: ${clientDetails?.firstName} ${clientDetails?.lastName
              },\nEmployee: ${event.employeeId
                ? `${employeeDetails?.firstName || ""} ${employeeDetails?.lastName ?? ""
                }`
                : "N/A"
              }`,
            description: event?.note,
            extendedProps: {
              ...event,
              client: clientDetails,
              employee: employeeDetails,
            },
            ...styleProperies,
            textColor:
              event.status === constant.EVENT_STATUS.CANCELLED
                ? "white"
                : "black",
          };
        });
      }
      return calendarEvents;
    },
  });

  const handleSyncEmployee = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to employees",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncToEmployee.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };
  const handleSyncClient = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to clients.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncToClient.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const handleAutoAssign = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are assigning the unassigned events",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          assignMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const handleDateClick = (info) => {
    const clickedDate = new Date(info.date);
    const today = new Date();

    if (clickedDate >= today.setHours(0, 0, 0, 0)) {
      if (currentView == "timeGridDay" || currentView == "timeGridWeek") {
        const today = new Date();
        if (clickedDate >= today) setShow(info);
      } else {
        const selectedDate = info.dateStr;
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.changeView("timeGridDay", selectedDate);
        }
      }
    } else console.log("Past date selected");
  };

  const handleDragging = ({ event, revert }) => {
    if (event?.extendedProps && event?.extendedProps.isCancelled) {
      toastAlert("warning", "Cannot update cancelled events");
      revert();
      return;
    }
    const updatedEnd = moment(event?.end);
    const updatedStart = moment(event?.start);
    const newEventStart = moment(event?.extendedProps?.startTime);
    if (
      !newEventStart.isValid() ||
      !updatedStart.isSame(newEventStart, "day")
    ) {
      // if the event date not matched with the date dropped
      revert();
      toastAlert("warning", "Cannot change date of the event");
      return;
    }

    const update = {
      override: false,
      id: event?.extendedProps?._id,
      clientId: event?.extendedProps.clientId,
      employeeId: event?.extendedProps.employeeId || null,
      note: event?.extendedProps?.note || "",
      startTime: updatedStart.format(constant.DATE_FORMAT),
      endTime: updatedEnd.format(constant.DATE_FORMAT),
    };
    mutation.mutate({ data: update, revert });
  };

  const syncToEmployee = useMutation({
    mutationFn: () => updateEventSteps(1),
    onSuccess: async (resp) => {
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });

  const syncToClient = useMutation({
    mutationFn: () => updateEventSteps(2),
    onSuccess: async (resp) => {
      console.log("Sync Events", resp);
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });

  const assignMutation = useMutation({
    mutationFn: async () => {
      const assigningEvents = await assignEvents();
      return assigningEvents?.data || {};
    },
    onSuccess: async (resp) => {
      if (resp && resp?.message && resp?.statusCode == 200) {
        toastAlert("success", resp.message);
        refetch();
      }
      return;
    },
  });

  const overrideEmployeeAssign = (responseData, arg) => {
    let reason = responseData?.data?.reason ? responseData?.data?.reason : responseData?.message
    Swal.fire({
      title: "Are you sure?",
      text: `You are going to assign this event as ${reason?.toLowerCase()} `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, override!",
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          ...arg.data,
          override: true,
        };
        mutation.mutate({ ...arg, data: body, });
      }
    });
  };

  const mutation = useMutation({
    mutationFn: (body) => updateEvent(body.data),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
    onError: (error, arg) => {
      toastAlert("error", error.response?.data?.message);
      arg.revert();
      if (error.status == 422) {
        return overrideEmployeeAssign(error.response.data, arg)
      }
    },
  });

  /****************************************Cancel Event***************************************/

  const handleCancel = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to cancel this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) cancelMutation.mutate(id);
    });
  };

  const cancelMutation = useMutation({
    mutationFn: async (id) => {
      return await updateEventStatus(id, constant.EVENT_STATUS.CANCELLED);
    },
    onSuccess: () => {
      toastAlert("success", "Event has been cancelled");
      refetch();
    },
  });

  /****************************************Delete Event***************************************/

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) deleteMutation.mutate(id);
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteEvent(id);
    },
    onSuccess: () => {
      toastAlert("success", "Event has been deleted");
      refetch();
    },
  });

  const handleEventClick = (arg) => {
    clearTimeout(clickTimer.current);

    clickTimer.current = setTimeout(() => {
      let id = arg?.event?.extendedProps?._id;
      Swal.fire({
        icon: "warning",
        title: "Actions",
        text: "You won't be able to revert this!",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonText: "Delete Event",
        denyButtonText: `Cancel Event`,
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(id);
        } else if (result.isDenied) {
          handleCancel(id);
        }
      });
    }, 300);
  };

  const handleEventDoubleClick = (arg) => {
    clearTimeout(clickTimer.current);
    const clickedEventDate = new Date(arg.event.start);
    const today = new Date();
    if (clickedEventDate >= today.setHours(0, 0, 0, 0)) {
      setShow(arg);
    }
  };

  return (
    <div className="main-content">
      <div className="commonSearchBar my-3">
        <h4>Employee Management</h4>
        <div className="endContent">
          <div className="position-relative">
            {Object.keys(filterData).length && Object.keys(filterData).filter(item => filterData[item]['value']).length ? <div className="redDot" /> : ""}
            <button
              className="greenBtn calendar-btn filter_button_container"
              type="button"
              title="Filter By"
              onClick={handleFilterShow}
            >
              <CiFilter size={30} />
            </button>
          </div>
          <button
            className="greenBtn calendar-btn"
            type="button"
            title="Auto Assign"
            onClick={handleAutoAssign}
          >
            <MdAssignmentInd size={30} />
          </button>
          <button
            className="greenBtn calendar-btn"
            type="button"
            title="Sync with employee"
            onClick={handleSyncEmployee}
          >
            <AiOutlineFileSync size={30} />
          </button>
          <button
            className="greenBtn calendar-btn"
            type="button"
            title="Sync with client"
            onClick={handleSyncClient}
          >
            <AiOutlineCloudSync size={35} />
          </button>
          <button
            className="greenBtn calendar-btn"
            type="button"
            title="Add Event"
            onClick={handleShow}
          >
            <IoMdAdd size={35} />
          </button>
        </div>
      </div>

      <FullCalendar
        ref={calendarRef}
        datesSet={(arg) => setCurrentView(arg.view.type)}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
        ]}
        events={data}
        editable={true}
        eventResizableFromStart={true}
        eventResize={handleDragging}
        eventDrop={handleDragging}
        dayMaxEventRows={true}
        nowIndicator={true}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        dateClick={handleDateClick}
        eventClick={(el) => {
          const clickedEventDate = new Date(el.event.start);
          const today = new Date();
          if (clickedEventDate >= today.setHours(0, 0, 0, 0)) {
            handleEventClick(el);
          }
        }}
        eventDidMount={(info) => {
          info.el.setAttribute("title", info.event.title);
          info.el.addEventListener("dblclick", () =>
            handleEventDoubleClick(info)
          );
        }}
      />

      {filterShow && <FilterModal show={filterShow} setShow={setFilterShow} refetch={refetch} data={filterData} setData={setFilterData} fromAdmin={true} />}
      {show && <AddEvent show={show} setShow={setShow} refetch={refetch} />}
    </div>
  );
};

export default Calendar;
