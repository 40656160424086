import axios from "axios";
import { store } from "../redux/store";

/* INTERCEPTOR STARTS */
const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  "Access-Control-Allow-Origin": "*",
});

http.interceptors.request.use(
  function (config) {
    const token = store.getState()?.auth?.details?.token?.auth;
    if (token) {
      config.headers["authorization"] = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* INTERCEPTOR ENDS */

/********************* AUTHENTICATION STARTS *********************/
export const signIn = async (body) => {
  return await http.post(`/users/login`, body);
};

export const logOut = async () => {
  return await http.get(`/users/logout`);
};

export const getUserProfile = async () => {
  return await http.get(`/users/user-profile`);
};

export const uploadFile = async (body) => {
  return await http.post(`/users/upload-file`, body);
};

export const updateUser = async (id, body) => {
  return await http.put(`/users/${id}`, body);
};

export const resetPassword = async (body) => {
  return await http.post(`/users/change-password`, body);
};

export const addUser = async (body) => {
  return await http.post(`/users`, body);
};

export const getUsers = async (params) => {
  return await http.get(`/users`, { params: params });
};

export const getUserByEvents = async (params) => {
  return await http.get(`/users/byEvent`, { params: params });
};

export const deleteUser = async (id) => {
  return await http.delete(`users/${id}`);
};

export const getUserDetails = async (id) => {
  return await http.get(`/users/user-profile?id=${id}`);
};

export const addTimeOff = async (body) => {
  return await http.post(`/leaves`, body);
};

export const getTimeOff = async (params) => {
  return await http.get(`/leaves`, { params: params });
};

export const updateLeaveStatus = async (id, body) => {
  return await http.put(`/leaves/update-status/${id}`, body);
};

export const handleDeleteLeave = async (id) => {
  return await http.delete(`/leaves/${id}`);
};

export const addEvent = async (body) => {
  return await http.post(`/events`, body);
};

export const updateEvent = async (body = {}) => {
  const { id, isReoccurring, repeatOn, endsOn, ...rest } = body
  return await http.put(`/events/${id}`, rest);
};

export const getEvents = async (params = {}) => {
  return await http.get(`/events`, { params: params });
};

export const deleteEvent = async (id) => {
  return await http.delete(`/events/${id}`);
};

export const updateEventStatus = async (id, status) => {
  return await http.put(`/events/update-status/${id}`, { status: status });
};

export const updateEventSteps = async (steps) => {
  return await http.put(`/events/update-event-steps`, { steps: steps });
};

export const assignEvents = async (params = {}) => {
  return await http.get(`/events/auto-assign`);
};

export const syncEvents = async (params = {}) => {
  return await http.get(`/events/sync-events`);
};

export const getPayouts = async (params = {}) => {
  return await http.get(`/payout`, { params: params });
};

export const addPayout = async (body) => {
  return await http.post(`/payout`, body);
};

export const downloadTimesheet = async (params = {}) => {
  return await http.get(`/payout/download-timesheet`, { params: params, responseType: "blob", });
};

export const uploadSignFile = async (body) => {
  return await http.post(`/payout/upload-file`, body);
};