import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";
import * as images from "../../assets/image";
import FilterModal from "../../common/FilterModal";
import useRole from "../../hooks/useRole";
import { downloadTimesheet, getPayouts } from "../../services/services";
import { constant } from "../../utils/constants";
import { getTableIndex, downloadFile } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { MdOutlineFileDownload } from "react-icons/md";

const PayOut = () => {
  const [search, setSearch] = useState();
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [filterShow, setFilterShow] = useState(false)
  const [filterData, setFilterData] = useState({})

  const role = useRole();

  const handleFilterShow = () => setFilterShow(true)

  const { data, refetch } = useQuery({
    queryKey: ["payout-list", page, filterData],
    queryFn: async () => {
      let params = { page: page, limit: constant.PAGE_LIMIT };

      if (Object.keys(filterData).length && Object.keys(filterData).filter(item => filterData[item]).length) {
        params.range = `${filterData.start}TO${filterData.end}`
      }

      if (role !== constant.ROLE.EMPLOYEE) params.needEmployeeDetails = true
      if (search) params.search = search;

      const resp = await getPayouts(params);
      return resp?.data?.data || {};
    },
  });

  const handleSampleDownload = async () => {
    try {
      let params = { page: page, limit: constant.PAGE_LIMIT };

      if (Object.keys(filterData).length && Object.keys(filterData).filter(item => filterData[item]).length) {
        params.range = `${filterData.start}TO${filterData.end}`
      }

      if (search) params.search = search;
      let resp = await downloadTimesheet(params);
      let fileName = params?.range ? `${params?.range}_timesheet.xlsx` : "timesheet.xlsx"
      downloadFile(resp?.data, fileName);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Payout</h4>
        <div className="endContent">
          <div className="clientSearch">
            <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            />
            <input
              type="text"
              placeholder="Type and press enter to search."
              className="clientSearchInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key == "Enter" && search.trim() !== "" && refetch()
              }
              onKeyUp={(e) =>
                e.target.value == "" && e.key == "Backspace" && refetch()
              }
            />
          </div>
          {role === constant.ROLE.ADMIN &&
            <React.Fragment>
              <div className="position-relative">
                {Object.keys(filterData).length && Object.keys(filterData).filter(item => filterData[item]).length ? <div className="redDot" /> : ""}
                <button
                  className="greenBtn calendar-btn"
                  type="button"
                  title="Filter By"
                  onClick={handleFilterShow}
                >
                  <CiFilter size={30} />
                </button>
              </div>
              <button
                className="greenBtn calendar-btn"
                type="button"
                title="Auto Assign"
                onClick={handleSampleDownload}
              >
                <MdOutlineFileDownload size={30} />
              </button>
            </React.Fragment>}
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th>S. No</th>
                    {role !== constant.ROLE.EMPLOYEE && (<th>Employee Name</th>)}
                    <th>Client Name</th>
                    <th>Assignment date</th>
                    <th>Check-In</th>
                    <th>Check-Out</th>
                    <th>Note</th>
                    <th>Client Initials</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.payouts && data?.payouts?.length > 0 ? (
                    data?.payouts?.map((payout, index) => {
                      const employeeDetails = payout?.eventDetails?.employeeDetails || null
                      return (
                        <tr key={`payout_${index}`}>
                          <td>{getTableIndex(page, index)}</td>
                          {role !== constant.ROLE.EMPLOYEE && (
                            <td className="text-capitalize">
                              {employeeDetails
                                ? `${employeeDetails?.firstName || ""} ${employeeDetails?.lastName || ""}`
                                : "Event not avaialable"}
                            </td>
                          )}
                          <td className="text-capitalize">
                            {payout?.eventDetails
                              ? `${payout?.eventDetails && payout?.eventDetails?.clientDetails?.firstName} ${payout?.eventDetails?.clientDetails?.lastName}`
                              : "Event not avaialable"}
                          </td>

                          <td>{moment(payout?.eventDetails.startTime).format("ll")}</td>
                          <td>
                            {moment(payout?.checkIn, "HH:mm").format("LT")}
                          </td>
                          <td>
                            {moment(payout?.checkOut, "HH:mm").format("LT")}
                          </td>
                          <td>{payout?.note || "N/A"}</td>
                          <td className="sign-area">{payout.signature ? <img src={payout.signature} className="digital-sign" /> : "N/A"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={data?.total_pages}
              />
            </div>
          </div>
        </div>
      </div>
      {filterShow && <FilterModal show={filterShow} setShow={setFilterShow} refetch={refetch} data={filterData} setData={setFilterData} />}
    </section>
  );
};

export default PayOut;
