import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { AiOutlineFileSync } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import Swal from "sweetalert2";
import { getEvents, syncEvents } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import AddRequest from "../client-request/AddRequest";
import useRole from "../../hooks/useRole";

const ClientCalendar = () => {
  const calendarRef = useRef(null);

  const role = useRole();

  const [currentView, setCurrentView] = useState("");

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const getEventStyle = (eventObj, employeeDuration) => {
    let classname = "";
    let backgroundColor = "red";
    let isOverBooked = false;

    const startDate = new Date(eventObj?.startTime); // Handle timezone shift
    const fullDate = startDate.toISOString().split("T")[0];

    const isCancelled = eventObj.status === constant.EVENT_STATUS.CANCELLED;
    if (
      employeeDuration &&
      employeeDuration[fullDate] &&
      employeeDuration[fullDate][eventObj.employeeId] &&
      employeeDuration[fullDate][eventObj.employeeId] >=
      constant.MAX_DURATION_PER_EMPLOYEE
    ) {
      isOverBooked = true;
    }

    if (isCancelled) {
      // Cancelled events
      classname = "cancelled_event";
      backgroundColor = "blue";
    } else if (!eventObj?.employeeId || eventObj?.employeeId == "") {
      // Not Assigned
      classname = "unassigned_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && !eventObj?.sync && isOverBooked) {
      // Employee overboooked over 8 hours and the vent is not synced
      classname = "overbooked_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && !eventObj?.sync) {
      // Assigned but not synced
      classname = "notsync_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && eventObj?.sync) {
      // Assigned and synced
      classname = "synced_assigned_event";
      backgroundColor = "green";
    } else if (isOverBooked) {
      classname = "overbooked_event";
      backgroundColor = "red";
    }

    return { backgroundColor: backgroundColor, className: classname };
  };

  const { data, refetch } = useQuery({
    queryKey: ["event-list"],
    queryFn: async () => {
      let params = {
        sortedBy: constant.SORTED_BY.START_TIME, sortBy: constant.SORT_BY.ASC
      }

      const resp = await getEvents(params);

      let calendarEvents = [];

      if (
        resp.data &&
        resp?.data?.data?.events &&
        resp?.data?.data?.events?.length
      ) {
        let employeeDuration = {};
        calendarEvents = resp?.data?.data?.events.map((event) => {
          const clientDetails = event?.clientDetails || null;
          const employeeDetails = event?.employeeDetails || null;

          const startDate = new Date(event?.startTime); // 8 hours color shift code
          const fullDate = startDate.toISOString().split("T")[0];
          if (event.employeeId && event.employeeId !== "") {
            if (employeeDuration[fullDate]) {
              const innerObject = { ...employeeDuration[fullDate] };
              if (innerObject[event.employeeId]) {
                innerObject[event.employeeId] += event.duration;
              } else {
                innerObject[event.employeeId] = event.duration;
              }
              employeeDuration[fullDate] = innerObject;
            } else
              employeeDuration[fullDate] = {
                [event.employeeId]: event.duration,
              };
          }

          const styleProperies = getEventStyle(event, employeeDuration);
          return {
            start: new Date(event?.startTime),
            end: new Date(event?.endTime),
            title: `Employee: ${event.employeeId
              ? `${employeeDetails?.firstName || ""} ${employeeDetails?.lastName ?? ""
              }`
              : "N/A"
              },\nTime: ${moment(event?.startTime).format("LT")} - ${moment(
                event?.endTime
              ).format("LT")}`,
            description: event?.note,
            extendedProps: {
              ...event,
              client: clientDetails,
              employee: employeeDetails,
            },
            // ...styleProperies,
            // textColor:
            //   event.status === constant.EVENT_STATUS.CANCELLED
            //     ? "white"
            //     : "black",
          };
        });
      }
      return calendarEvents;
    },
  });

  const handleEventSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to your google calendar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncEventsMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncEventsMutation = useMutation({
    mutationFn: () => syncEvents(),
    onSuccess: async (resp) => {
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });

  const handleEventClick = (info) => {
    const selectedDate = info.event.start.toISOString().split("T")[0];
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay", selectedDate);
  };

  const handleDateClick = (info) => {
    const clickedDate = new Date(info.date);
    const today = new Date();
    if (clickedDate >= today.setHours(0, 0, 0, 0)) {
      if (currentView == "timeGridDay" || currentView == "timeGridWeek") {
        if (role !== constant.ROLE.CLIENT) return;
        const today = new Date();
        if (clickedDate >= today) setShow(info);
      } else {
        const selectedDate = info.dateStr;
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.changeView("timeGridDay", selectedDate);
        }
      }
    } else console.log("Past date selected");
  };

  return (
    <div className="main-content">
      <div className="commonSearchBar my-3">
        <h4>Appointments</h4>
        <div className="endContent">
          <button
            className="greenBtn"
            type="button"
            title="Sync to google calendar"
            onClick={handleEventSync}
          >
            <AiOutlineFileSync size={30} />
          </button>
          {role === constant.ROLE.CLIENT && <button
            className="greenBtn calendar-btn"
            type="button"
            title="Add Event"
            onClick={handleShow}
          >
            <IoMdAdd size={35} />
          </button>}
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        datesSet={(arg) => setCurrentView(arg.view.type)}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
        ]}
        events={data}
        dayMaxEventRows={true}
        nowIndicator={true}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        eventClick={(el) => handleEventClick(el)}
        eventDidMount={(info) => {
          info.el.setAttribute("title", info.event.title);
        }}
        dateClick={handleDateClick}
      />
      {show && <AddRequest show={show} setShow={setShow} refetch={refetch} />}
    </div >
  );
};

export default ClientCalendar;
