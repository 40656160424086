import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as yup from "yup";
import NavigateBack from "../../common/NavigateBack";
import { addPayout, getEvents, getUserByEvents, uploadSignFile, } from "../../services/services";
import { constant } from "../../utils/constants";
import { base64toImageFile, setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";
import SignatureCanvas from "../../common/SignatureCanvas";

const AddPayOut = () => {

  const [signModal, setSignModal] = useState(false)
  const [signed, setSigned] = useState(null)
  const handleShowSignModal = () => setSignModal(true)

  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      clientId: "",
      eventId: "",
      employeeId: "",
      shiftDate: new Date(),
      checkIn: "",
      checkOut: "",
      note: "",
    },
    validationSchema: yup.object().shape({
      clientId: yup.object().shape({
        value: yup.string().required().label("Client"),
      }),
      eventId: yup.object().shape({
        value: yup.string().required().label("Shift Time"),
      }),
      checkIn: yup.string().required().label("Check In"),
      checkOut: yup
        .string()
        .required()
        .label("Check Out")
        .test(
          "is-greater-than-startTime",
          "Check out time should be later than check in time",
          function (value) {
            const { shiftDate, checkIn } = this.parent;
            const start = new Date(shiftDate);
            const end = new Date(shiftDate);
            setTimeFromString(start, checkIn);
            setTimeFromString(end, value);
            return end > start;
          }
        ),

    }),
    onSubmit: async (values) => {
      if (!signed) {
        toastAlert("error", "Please sign before saving")
        return null;
      }

      const file = base64toImageFile(signed)
      let formData = new FormData();
      formData.append("file", file, "signature.png");

      const imageUpload = await uploadSignFile(formData)
      if (imageUpload.status !== 200) {
        toastAlert("error", "error uploading signature file")
        return null;
      }

      let body = {
        eventId: values.eventId.value,
        note: values.note,
        checkIn: values.checkIn,
        checkOut: values.checkOut,
        signature: imageUpload.data.data.fileUrl
      }
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => addPayout(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate(-1)
    },
  });

  const getClients = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.CLIENT,
    };
    if (search) params.search = search;
    let resp = await getUserByEvents(params);
    let array = (await resp?.data?.data) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i?.userDetails?.firstName} ${i?.userDetails?.lastName}`,
        value: i?.userDetails?._id,
      })),
      hasMore: false,
      additional: {
        page: 1,
      },
    };
  };
  const getEventsList = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      clientId: values?.clientId?.value,
      previousEvents: 'true'
    };
    if (search) params.search = search;
    let resp = await getEvents(params);
    let array = (await resp?.data?.data?.events) ?? [];
    return {
      options: array.map((i) => {
        const startTime = moment(i.startTime)
        const endTime = moment(i.endTime)
        const labelValue = `${startTime.format('ll')} (${startTime.format(constant.HOURS_FORMAT)} - ${endTime.format(constant.HOURS_FORMAT)})`
        return ({
          label: labelValue,
          value: i._id,
        })
      }),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <div className="addHeader mb-3">
            <span className="heading20">Time sheet</span>
          </div>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">
                  <div className="col-md-12 mb-3 ">
                    <div className="form-group">
                      <label className="labelTxt">
                        Client <span className="text-danger">*</span>
                      </label>
                      <AsyncPaginate
                        additional={{
                          page: 1,
                        }}
                        styles={constant.REACT_SELECT_CUSTOM_STYLE}
                        debounceTimeout={500}
                        loadOptions={getClients}
                        value={values?.clientId}
                        onChange={(e) => {
                          setFieldValue("clientId", e);
                        }}
                        onBlur={() => setFieldTouched("clientId", true)}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.clientId && errors?.clientId?.value}
                      </small>
                    </div>
                  </div>
                  {values.clientId &&
                    <div className="col-md-12 mb-3 ">
                      <div className="form-group">
                        <label className="labelTxt">
                          Shift Time <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          additional={{
                            page: 1,
                          }}
                          styles={constant.REACT_SELECT_CUSTOM_STYLE}
                          debounceTimeout={500}
                          key={values?.clientId?.value}
                          loadOptions={getEventsList}
                          value={values?.eventId}
                          onChange={(e) => {
                            setFieldValue("eventId", e);
                          }}
                          onBlur={() => setFieldTouched("eventId", true)}
                        />
                        <small className="text-danger requiredTxt">
                          {touched.eventId && errors?.eventId?.value}
                        </small>
                      </div>
                    </div>}
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-In
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="checkIn"
                        className="timePicker inputBox"
                        value={values?.checkIn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched.checkIn && errors.checkIn}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-Out
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="checkOut"
                        className="timePicker inputBox"
                        value={values?.checkOut}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.checkOut && errors.checkOut}
                      </small>
                    </div>
                  </div>


                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Note</label>
                      <textarea
                        rows={3}
                        name="note"
                        className="inputBox"
                        value={values?.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.note && errors.note}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="labelTxt">Client Initials</label>
                    <div className="p-3 mb-2">
                      {signed && <img src={signed} />}
                    </div>
                    <button className="addBtn me-3" type="button" onClick={handleShowSignModal}>
                      {signed ? "Re-sign" : "Signature"}
                    </button>
                  </div>
                  <div className="col-md-12 ">
                    <p className="text-danger requiredTxt">
                      <b>Note:</b> Once you've submitted the payout details,
                      they can't be changed. Make sure to review them carefully
                      before submitting!
                    </p>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Add
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
        {signModal && <SignatureCanvas show={signModal} handleClose={() => setSignModal(false)} signed={signed} setSigned={setSigned} />}
      </section>
    </>
  );
};

export default AddPayOut;
