import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { getEvents } from "../../services/services";
import { constant } from "../../utils/constants";
import { getTableIndex } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import AddRequest from "./AddRequest";
import { IoMdAdd } from "react-icons/io";

const Request = () => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [isToggled, setIsToggled] = useState(true);

  const handleShow = () => setShow(true);
  const handleToggle = () => setIsToggled(!isToggled);

  const { data, refetch } = useQuery({
    queryKey: ["shift-list", page],
    queryFn: async () => {
      let params = { page: page, limit: constant.PAGE_LIMIT, requestedEvents: true };
      if (search) params.search = search;
      const resp = await getEvents(params);
      return resp?.data?.data || {};
    },
  });

  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Appointment Request</h4>
        <div className="endContent">
          {/* <div className="toggle-container">
            <div
              className={`toggle-switch ${isToggled ? "toggled" : ""}`}
              onClick={handleToggle}
            >
              <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
            </div>
            <label className="label-text">Future Requests</label> */}
          {/* </div> */}
          <button
            className="greenBtn calendar-btn"
            type="button"
            title="Add Event"
            onClick={handleShow}
          >
            <IoMdAdd size={35} />
          </button>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th>S. No</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Note</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.events && data?.events?.length > 0 ? (
                    data?.events?.map((event, index) => {
                      const clientDetails = event?.clientDetails || null;
                      return (
                        <tr key={`event_${index}`}>
                          <td> {getTableIndex(page, index)}</td>
                          <td>{moment(event?.startTime).format("ll")}</td>
                          <td>
                            {moment(event?.startTime).format("LT")} -{" "}
                            {moment(event?.endTime).format("LT")}
                          </td>
                          <td>{event?.note || "--"}</td>
                          <td>{`${clientDetails?.userAddress?.address} ${clientDetails?.userAddress?.city} ${clientDetails?.userAddress?.state} ${clientDetails?.userAddress?.country} (${clientDetails?.userAddress?.zipCode})`}</td>
                          <td>{clientDetails?.phoneNumber}</td>
                          <td>{clientDetails?.email}</td>
                          <td>{moment(event?.createdAt).format("lll")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={data?.total_pages}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <AddRequest show={show} setShow={setShow} refetch={refetch} />}
    </section>
  );
};

export default Request;
