import React from "react";
import { Route, Routes } from "react-router";
import ChangePassword from "../pages/auth/ChangePassword";
import EditProfile from "../pages/auth/EditProfile";
import Profile from "../pages/auth/Profile";
import AddPayOut from "../pages/payout/AddPayOut";
import PayOut from "../pages/payout/PayOut";
import Shifts from "../pages/shifts/Shifts";
import AddTimeOff from "../pages/time-off/AddTimeOff";
import TimeOff from "../pages/time-off/TimeOff";
import NotFound from "../common/notFound/NotFound";
import ClientCalendar from "../pages/calender/ClientCalendar";

const EmployeeRoutes = () => {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="calendar" element={<ClientCalendar />} />
      <Route path="edit-profile" element={<EditProfile />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="shifts" element={<Shifts />} />
      <Route path="payout" element={<PayOut />} />
      <Route path="add-timesheet" element={<AddPayOut />} />
      <Route path="time-off" element={<TimeOff />} />
      <Route path="add-time-off" element={<AddTimeOff />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default EmployeeRoutes;
