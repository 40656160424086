export const constant = Object.freeze({
  /*******************Roles******************/
  ROLE: {
    ADMIN: "admin",
    EMPLOYEE: "employee",
    CLIENT: "client",
  },

  /*******************Status Code******************/
  STATUS_CODE: {
    SUCCESS: 200,
    NOT_FOUND: 404,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
  },
  DEFAULT_AVAILABILITY: {
    START: "10:00",
    END: "17:00"
  },

  /*******************Image Format******************/

  SUPPORTED_FORMATS: ["image/jpg", "image/jpeg", "image/png"],

  /*******************Date Format******************/
  DATE_FORMAT: "YYYY-MM-DDTHH:mm:ss",
  HOURS_FORMAT: "hh:mm a",
  DATE_ONLY_FORMAT: "YYYY-MM-DD",

  /**********************React Select Custom Style****************/
  REACT_SELECT_CUSTOM_STYLE: {
    control: (styles) => ({
      ...styles,
      borderRadius: "12px",
      padding: "12px",
      width: "100%",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999, // Add your desired z-index value here
    }),
  },

  /*************** Common Constants****************/
  DEFAULT_COUNTRY: "us",
  PASSWORD_SUFFIX: "@123",

  /********************Admin Signup***********/
  ADMIN_NAME: "Admin",
  ADMIN_EMAIL: "admin@itech.com",
  ADMIN_PASSWORD: "Admin@123",

  /*****************Pagination*****************/
  PAGE_NO_ONE: 1,
  PER_PAGE_TEN: 10,
  PAGE_LIMIT: 20,
  MAX_EVENT_DURATION_MINUTES: 720, // 12 hours
  MAX_DURATION_PER_EMPLOYEE: 480, // 8 hours

  LEAVE_STATUS: {
    PENDING: "pending",
    APPROVED: "approved",
    REJECTED: "rejected",
  },
  EVENT_STATUS: {
    PENDING: "pending",
    APPROVED: "approved",
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    CANCELLED: "cancelled"
  },
  DISCOVERY_DOCS: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  SCOPES: "https://www.googleapis.com/auth/calendar.events",

  SORT_BY: {
    ASC: "ASC",
    DESC: "DESC",
  },

  SORTED_BY: {
    FIRST_NAME: "firstName",
    START_TIME: "startTime"
  }
});
