import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import * as images from "../../assets/image";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { constant } from "../../utils/constants";
import { getDaysString } from "../../utils/function";
import moment from "moment";

const Profile = () => {
  const details = useDetails();
  const role = useRole();
  return (
    <div className={"main-content"}>
      <div className="userData">
        <div className="d-flex justify-content-between">
          <div className="userLeft">
            <div className="userImg position-relative">
              <img
                src={
                  details?.profilePhoto ? details.profilePhoto : images.profile
                }
                alt="profile"
                width={250}
                className="profileUploadImg"
              />
            </div>
            <div className="userInfo p-3">
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">First Name</h6>
                <p className="mb-0">{details?.firstName}</p>
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Last Name</h6>

                <p className="mb-0">{details?.lastName}</p>
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Email</h6>

                <p className="mb-0">{details?.email}</p>
              </div>
            </div>
          </div>
          <div className="userRight">
            <Link className="greenBtn" to={"../edit-profile"} state={details}>
              Edit Profile
            </Link>
          </div>
        </div>
        {role == constant.ROLE.EMPLOYEE && (
          <div className="userWork ps-4">
            <div className="workingDays">
              <h5 className="prefferedDays">Preffered Working Days</h5>
            </div>
            {details?.employeeDetails?.availability?.map((item, index) => {
              return (
                <div className="row mt-4" key={index}>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelTxt">Day</label>

                      <p className="mb-0"> {getDaysString([item?.day])}</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelTxt">Start Time</label>

                      <p className="mb-0">
                        {item?.startTime
                          ? moment(item?.startTime, "HH:mm")?.format("LT")
                          : "Not set"}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelTxt">End Time</label>

                      <p className="mb-0">
                        {item?.endTime
                          ? moment(item?.endTime, "HH:mm")?.format("LT")
                          : "Not set"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
