import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../common/Navbar";
import Sidebar from "../common/Sidebar";
import useDetails from "../hooks/useDetails";
import useRole from "../hooks/useRole";
import { constant } from "./constants";

export const UserAuth = () => {
  const role = useRole();
  const details = useDetails();
  let token = details?.token?.auth;

  if (token) {
    if (role == constant.ROLE.ADMIN) {
      return <Navigate to="/admin/calendar" replace={true} />;
    } else if (role == constant.ROLE.EMPLOYEE) {
      return <Navigate to="/employee/shifts" replace={true} />;
    } else {
      return (
        <>
          <Sidebar />
          <Navbar />
          <Outlet />
        </>
      );
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const EmployeeAuth = () => {
  const role = useRole();
  const details = useDetails();
  let token = details?.token?.auth;

  if (token) {
    if (role == constant.ROLE.ADMIN) {
      return <Navigate to="/admin/calendar" replace={true} />;
    } else if (role == constant.ROLE.CLIENT) {
      return <Navigate to="/calendar" replace={true} />;
    } else {
      return (
        <>
          <Sidebar />
          <Navbar />
          <Outlet />
        </>
      );
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const AdminAuth = () => {
  const role = useRole();
  const details = useDetails();
  let token = details?.token?.auth;

  if (token) {
    if (role == constant.ROLE.EMPLOYEE) {
      return <Navigate to="/employee/shifts" replace={true} />;
    } else if (role == constant.ROLE.CLIENT) {
      return <Navigate to="/calendar" replace={true} />;
    } else {
      return (
        <>
          <Sidebar />
          <Navbar />
          <Outlet />
        </>
      );
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const PublicAuth = () => {
  const role = useRole();
  const details = useDetails();
  let token = details?.token?.auth;

  if (token) {
    if (role == constant.ROLE.EMPLOYEE) {
      return <Navigate to="/employee/shifts" replace={true} />;
    }
    if (role == constant.ROLE.CLIENT) {
      return <Navigate to="/calendar" replace={true} />;
    } else {
      return <Navigate to="/admin/calendar" replace={true} />;
    }
  } else {
    return <Outlet />;
  }
};
